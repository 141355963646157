import jwt_decode from "jwt-decode";

import { PermissionFlags, PermissionName, Role } from "../models/api/Role";
import { Http401Page } from "../pages/error/Http401Page";
import { OrganizationTheme } from "../models/api/OrganizationTheme";
import { RoleCategoryAccess } from "../models/api/RoleCategoryAccess";

const CF_Application_PortalEnv_Dev = "dev";
const CF_Application_PortalEnv_Staging = "staging";
const CF_Application_PortalEnv_Production = "production";

const INERTIA_ORG_ID_DEV = "e3bdee4e-dde4-43df-a0ac-e88f32e1eeec";
const INERTIA_ORG_ID_STAGING = "e3bdee4e-dde4-43df-a0ac-e88f32e1eeec";
const INERTIA_ORG_ID_PRODUCTION = "e3bdee4e-dde4-43df-a0ac-e88f32e1eeec";

export default {
  getCookieByName(name: string): string {
    const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    if (match) return match[2];
    else return "";
  },

  getUserToken(): string {
    return decodeURI(this.getCookieByName("JWT_TOKEN"));
  },

  getJwtToken(): string | null {
    let token = localStorage.getItem("JWT_TOKEN");
    if (token) token = decodeURI(token);
    return token;
  },

  getDecodedJwtToken(): any {
    return jwt_decode(this.getJwtToken()!);
  },

  isImpersonated(): boolean {
    const decodedToken: any = this.getDecodedJwtToken();
    return decodedToken.impersionatedLogin;
  },

  isDevelopment(): boolean {
    const decodedToken: any = jwt_decode(this.getJwtToken()!);
    return decodedToken.isDevelopment;
  },

  isStaging(): boolean {
    const decodedToken: any = jwt_decode(this.getJwtToken()!);
    return decodedToken.isStaging;
  },

  isInhouse(): boolean {
    const decodedToken: any = jwt_decode(this.getJwtToken()!);
    return decodedToken.isInhouse == 1;
  },

  getUserId(): string {
    const decodedToken: any = jwt_decode(this.getJwtToken()!);
    return decodedToken.userId;
  },

  getOrgId(): string {
    const decodedToken: any = jwt_decode(this.getJwtToken()!);
    return decodedToken.orgId;
  },

  getUserRole(): Role {
    const decodedToken: any = jwt_decode(this.getJwtToken()!);
    return {
      name: decodedToken["role.name"],
      canDelete: false, // Can this role be deleted
      canEdit: false, // Can this role be edited
      permissionsRole: decodedToken["role.permissionsRole"],
      permissionsOrg: decodedToken["role.permissionsOrg"],
      permissionsUser: decodedToken["role.permissionsUser"],
      permissionsBranding: decodedToken["role.permissionsBranding"],
      permissionsDashboard: decodedToken["role.permissionsDashboard"],
      permissionsDevice: decodedToken["role.permissionsDevice"],
      permissionsSubOrganization: decodedToken["role.permissionsSubOrganization"],
      permissionsAlert: decodedToken["role.permissionsAlert"],
      permissionsAccessGroup: decodedToken["role.permissionsAccessGroup"],
      permissionsDeviceGroup: decodedToken["role.permissionsDeviceGroup"],
      permissionsApiKey: decodedToken["role.permissionsApiKey"],
      permissionsWebhooks: decodedToken["role.permissionsWebhooks"],
      permissionsEventLog: decodedToken["role.permissionsEventLog"],
      permissionsImport: decodedToken["role.permissionsImport"],
      permissionsDocumentation: decodedToken["role.permissionsDocumentation"],
      permissionsAdminMenu: decodedToken["role.permissionsAdminMenu"],
      permissionsCustomDashboard: decodedToken["role.permissionsCustomDashboard"],
      permissionsProvision: decodedToken["role.permissionsProvision"],
      permissionsBulkOperation: decodedToken["role.permissionsBulkOperation"],
      permissionsTag: decodedToken["role.permissionsTag"],
      permissionsPortalLogin: decodedToken["role.permissionsPortalLogin"],
      permissionsDeviceInstallation: decodedToken["role.permissionsDeviceInstallation"],
      permissionsDeviceMove: decodedToken["role.permissionsDeviceMove"],
    } as Role;
  },

  getRoleCategoryAccess(): RoleCategoryAccess {
    const decodedToken: any = jwt_decode(this.getJwtToken()!);

    return {
      //PermissionsOrg,PermissionsBranding,PermissionsApiKey,PermissionsWebhooks,PermissionsEventLog
      organizationSettingsCategoryAccess:
        decodedToken["role.permissionsOrg"] > 0 ||
        decodedToken["role.permissionsBranding"] > 0 ||
        decodedToken["role.permissionsApiKey"] > 0 ||
        decodedToken["role.permissionsWebhooks"] > 0 ||
        decodedToken["role.permissionsEventLog"] > 0,
      // PermissionsUser,PermissionsRole,PermissionsAccessGroup
      userManagementCategoryAccess:
        decodedToken["role.permissionsUser"] > 0 ||
        decodedToken["role.permissionsRole"] > 0 ||
        decodedToken["role.permissionsAccessGroup"] > 0,
      // PermissionsDevice,PermissionsDeviceGroup,PermissionsDeviceInstallation,PermissionsDeviceMove
      deviceCategoryAccess:
        decodedToken["role.permissionsDevice"] > 0 ||
        decodedToken["role.permissionsDeviceGroup"] > 0 ||
        decodedToken["role.permissionsDeviceInstallation"] > 0 ||
        decodedToken["role.permissionsDeviceMove"] > 0,
      // PermissionsSubOrganization,PermissionsAdminMenu,PermissionsDocumentation,PermissionsCustomDashboard,PermissionsProvision
      othersCategoryAccess:
        decodedToken["role.permissionsSubOrganization"] > 0 ||
        decodedToken["role.permissionsAdminMenu"] > 0 ||
        decodedToken["role.permissionsDocumentation"] > 0 ||
        decodedToken["role.permissionsCustomDashboard"] > 0 ||
        decodedToken["role.permissionsProvision"] > 0,
      // PermissionsAlert,PermissionsDashboard,PermissionsImport
      noCategoryAccess:
        decodedToken["role.permissionsAlert"] > 0 ||
        decodedToken["role.permissionsDashboard"] > 0 ||
        decodedToken["role.permissionsImport"] > 0,
    } as RoleCategoryAccess;
  },

  getOrgTheme(): OrganizationTheme {
    const decodedToken: any = jwt_decode(this.getJwtToken()!);
    return {
      id: decodedToken["theme.id"] != "" ? decodedToken["theme.id"] : undefined,
      organizationId:
        decodedToken["theme.organizationId"] != ""
          ? decodedToken["theme.organizationId"]
          : undefined,
      organizationName: decodedToken["orgName"] != "" ? decodedToken["orgName"] : undefined,
      logoImageUri:
        decodedToken["theme.logoImageUri"] != "" ? decodedToken["theme.logoImageUri"] : undefined,
      iconImageUri:
        decodedToken["theme.iconImageUri"] != "" ? decodedToken["theme.iconImageUri"] : undefined,
      themeColor:
        decodedToken["theme.themeColor"] != "" ? decodedToken["theme.themeColor"] : undefined,
      useDarkText: decodedToken["theme.useDarkText"] == "true",
      companyName:
        decodedToken["theme.companyName"] != "" ? decodedToken["theme.companyName"] : undefined,
      domain: decodedToken["theme.domain"] != "" ? decodedToken["theme.domain"] : undefined,
      portalName:
        decodedToken["theme.portalName"] != "" ? decodedToken["theme.portalName"] : undefined,
      termsOfServiceUrl:
        decodedToken["theme.termsOfServiceUrl"] != ""
          ? decodedToken["theme.termsOfServiceUrl"]
          : undefined,
      privacyPolicyUrl:
        decodedToken["theme.privacyPolicyUrl"] != ""
          ? decodedToken["theme.privacyPolicyUrl"]
          : undefined,
      supportUrl:
        decodedToken["theme.supportUrl"] != "" ? decodedToken["theme.supportUrl"] : undefined,
      supportPh:
        decodedToken["theme.supportPh"] != "" ? decodedToken["theme.supportPh"] : undefined,
      supportEmail:
        decodedToken["theme.supportEmail"] != "" ? decodedToken["theme.supportEmail"] : undefined,
    } as OrganizationTheme;
  },

  getApplicationVersion(): string {
    const decodedToken: any = jwt_decode(this.getJwtToken()!);

    return decodedToken["application.version"];
  },

  checkPermission(permission: PermissionName, flag: PermissionFlags): boolean {
    const userRole = this.getUserRole();
    return (userRole[permission] & flag) != 0;
  },

  checkPermissionForRoute(
    permission: PermissionName,
    flag: PermissionFlags,
    component: JSX.Element,
  ): JSX.Element {
    if (this.checkPermission(permission, flag)) {
      return component;
    } else {
      return <Http401Page />;
    }
  },

  clear() {
    localStorage.removeItem("JWT_TOKEN");
  },

  authenticated(): boolean {
    let isAuthenticated = false;
    const token = this.getJwtToken();
    if (token) {
      const decodedToken: any = jwt_decode(token);
      // NOTE <===============
      // curtime is in msec
      // decodedToken.exp is in seconds
      const curtime = new Date().getTime();
      if (decodedToken && parseInt(decodedToken.exp, 10) > curtime / 1000) {
        isAuthenticated = true;
      }
    }
    return isAuthenticated;
  },

  getPortalEnv() {
    const decodedToken: any = jwt_decode(this.getJwtToken()!);
    const portalEnv = decodedToken.portalEnv.toLowerCase();

    if (portalEnv === CF_Application_PortalEnv_Staging) {
      return CF_Application_PortalEnv_Staging;
    } else if (portalEnv === CF_Application_PortalEnv_Production) {
      return CF_Application_PortalEnv_Production;
    }

    return CF_Application_PortalEnv_Dev;
  },

  isInertiaOrg(): boolean {
    const decodedToken: any = jwt_decode(this.getJwtToken()!);
    const orgId = decodedToken.orgId.toLowerCase();

    return orgId === this.getInertiaOrgId();

    // Inertia Dev
    // orgName => Inertia Engineering (Test)
    // orgId => 85768e96-e1f5-1b34-a0b9-3375a4e5f2c0

    // Inertia Staging
    // orgName => Inertia Engineering (Test)
    // orgId => 6a7ae8b6-33d5-49a2-8cda-6ef048e3c7b6

    // Inertia Prod
    // orgName => Inertia Engineering
    // orgId => e3bdee4e-dde4-43df-a0ac-e88f32e1eeec
  },

  getInertiaOrgId(): string {
    const environment = this.getPortalEnv();
    if (environment === CF_Application_PortalEnv_Dev) {
      return INERTIA_ORG_ID_DEV;
    } else if (environment === CF_Application_PortalEnv_Staging) {
      return INERTIA_ORG_ID_STAGING;
    } else if (environment === CF_Application_PortalEnv_Production) {
      return INERTIA_ORG_ID_PRODUCTION;
    }
    return "";
  },
};

import { FC, Suspense, lazy, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteComponentProps,
  Redirect,
} from "react-router-dom";
import _ from "lodash";
import { useLayout } from "./contexts/layout/layout-context";
import Header from "./components/layout/header/Header";
import { Navbar } from "./components/layout/Navbar";
import { LoadingSkeleton } from "./components/LoadingSkeleton";
import ScrollToTop from "./components/ScrollToTop";
import { Footer } from "./components/layout/Footer";
import { DeviceRoutes } from "./pages/devices/Routes";
import { ManageRoutes } from "./pages/manage/Routes";
import { AdminRoutes } from "./pages/admin/Routes";
import { InhouseRoutes } from "./pages/inhouse/Routes";
import { WelcomeRoutes } from "./pages/welcome/Routes";
import { InertiaFleedDashboardRoutes } from "./pages/inertia-fleet-dashboard/Routes";
import { DashboardRoutes } from "./pages/dashboard/Routes";
import { DocumentationRoutes } from "./pages/documentation/Routes";
import { GraphingRoutes } from "./pages/graphing/Routes";
import BrandingHelpers from "./helpers/BrandingHelpers";
import { UserEditPage } from "./pages/shared/UserEditPage";
import JwtTokenHelpers from "./helpers/JwtTokenHelpers";
import cSharpApi from "./services/api/CSharpApi";
import variables from "./scss/variables.module.scss";
import { useAuth } from "./contexts/auth/auth.context";
import { useBranding } from "./contexts/branding/branding.context";
import ColorHelpers from "./helpers/ColorHelpers";
import { cfHost } from "./config";

const Http404Page = lazy(() =>
  import("./pages/error/Http404Page").then(({ Http404Page }) => ({ default: Http404Page })),
);
const DeviceTransmissionPage = lazy(() =>
  import("./pages/devices/DeviceTransmissionPage").then(({ DeviceTransmissionPage }) => ({
    default: DeviceTransmissionPage,
  })),
);
const ReleasesPublicPage = lazy(() =>
  import("./pages/releases/ReleasesPublicPage").then(({ ReleasesPublicPage }) => ({
    default: ReleasesPublicPage,
  })),
);

export interface RouteConfig {
  path: string;
  component: (() => JSX.Element) | ((props: RouteComponentProps<any>) => JSX.Element);
  title?: string;
  routes?: RouteConfig[];
}

const getInitialRoutes = (isInertia: boolean): RouteConfig[] => {
  const HomeRoutes = isInertia ? InertiaFleedDashboardRoutes : WelcomeRoutes;

  return [
    ...AdminRoutes,
    ...DeviceRoutes,
    ...ManageRoutes,
    ...DashboardRoutes,
    ...DocumentationRoutes,
    ...HomeRoutes,
    ...GraphingRoutes,
    {
      path: "/go/user/profile",
      title: "My Profile",
      component: (props: RouteComponentProps<any>) => (
        <UserEditPage {...props} userId={JwtTokenHelpers.getUserId()} />
      ),
    },
    {
      path: "/go/dashboard/deviceTransmission",
      title: "Device Transmission",
      component: (props: RouteComponentProps<any>) => <DeviceTransmissionPage {...props} />,
    },
    {
      path: "/go/releases",
      title: "What's New",
      component: () => <ReleasesPublicPage />,
    },
    {
      path: "*",
      title: "Welcome",
      component: () => <Redirect to="/go/welcome" />,
    },
  ];
};

const defaultBrandIconSrc: string = "/images/img-fav32.png";

// const KEY_STATIC_REFRESH_ID = "@Freewave:static-refresh-id";

type Props = Record<string, never>;

const AuthenticatedApp: FC<Props> = () => {
  const [documentTitle, setDocumentTitle] = useState<string>();
  const auth = useAuth();
  const { updatePageTitle } = useLayout();
  const { branding } = useBranding();
  const [routes, setRoutes] = useState<RouteConfig[]>(new Array<RouteConfig>());

  useEffect(() => {
    // TO DO: after decouple React from CF, move that part to Login action
    cSharpApi.defaults.headers.authorization = `Bearer ${JwtTokenHelpers.getJwtToken()}`;
    cSharpApi.defaults.headers.post["Content-Type"] = "application/json";
    cSharpApi.defaults.headers.get["Content-Type"] = "application/json";
  }, [auth.token]);

  useEffect(() => {
    let allRoutes = _.cloneDeep(getInitialRoutes(auth.isInertiaOrg || auth.isInertiaSubOrg));

    if (JwtTokenHelpers.isInhouse()) {
      allRoutes = [..._.cloneDeep(InhouseRoutes), ...allRoutes];
    }
    setRoutes(allRoutes);
  }, [auth]);

  useEffect(() => {
    if (documentTitle !== undefined && updatePageTitle !== undefined) {
      updatePageTitle("");
      document.title = documentTitle;
    }
  }, [documentTitle]);

  useEffect(() => {
    const bgColor = branding.themeColor
      ? branding.themeColor
      : (variables.ColorPrimaryStatic as string);
    const fgColor = branding.useDarkText
      ? (variables.ColorBlackDark as string)
      : (variables.ColorWhite as string);

    document.documentElement.style.setProperty("--theme-bg-color", bgColor);
    document.documentElement.style.setProperty(
      "--theme-bg-color-rgb",
      ColorHelpers.hexToRGB(bgColor),
    );
    document.documentElement.style.setProperty("--theme-fg-color", fgColor);

    const iconPath = branding.iconImageUri ? branding.iconImageUri : defaultBrandIconSrc;
    let favIconLinkElement = document.querySelector("link[rel~='icon']");

    if (!favIconLinkElement) {
      favIconLinkElement = document.createElement("link");
      favIconLinkElement.setAttribute("rel", "icon");
      document.getElementsByTagName("head")[0].appendChild(favIconLinkElement);
    }
    favIconLinkElement.setAttribute("href", `${cfHost}${iconPath}`);
  }, [branding]);

  const setTitle = (routeTitle?: string) => {
    if (routeTitle) {
      const companyName = BrandingHelpers.getCompanyName();
      const title = `${routeTitle} - ${companyName} Data Portal`;
      setDocumentTitle(title);
    }
  };

  return (
    <Router>
      <Header />
      <Navbar />
      <div className="content-wrapper">
        <div className="inner-wrapper">
          <Suspense fallback={<LoadingSkeleton width="100%" height="100%" />}>
            <ScrollToTop />
            <Switch>
              {routes.map((route) => (
                <Route
                  strict={true}
                  path={route.path}
                  key={route.path}
                  render={(props) => {
                    setTitle(route.title);
                    return route.component(props);
                  }}
                />
              ))}
              <Route exact={true} path="*">
                <Http404Page />
              </Route>
            </Switch>
          </Suspense>
        </div>
      </div>
      <Footer />
    </Router>
  );
};

export default AuthenticatedApp;
